import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleRequest, getHandle } from '../../components/utility/drag_drop/index'
import { Headers, ListItem, Spinner, Wrapper, ListContainer, Text } from './style';

const TermsConditions = () => {
    const user = useSelector((state) => state.signin.user)
    const config = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user?.tokens?.access}`
    }
    const [terms, setTerms] = useState([]);
    const dispatch = useDispatch();
    async function RetrieveTerms(){
        const data = await getHandle({url:`${process.env.REACT_APP_BASE_APP_URL}/guide/tcs/`, config: config})
        const response = handleRequest(data, dispatch, config, '')
        if(response === 'success'){
            setTerms(data.data);
        }
    }
    useEffect(() => {
        RetrieveTerms();
    }, [])
  return (
    <Wrapper ht={terms.length > 0 ? "max-content":"100vh"}>
        {terms.length > 0?
            <>
                <Headers guide='true' center="center" >Terms and Conditions of Use</Headers>
                {
                    <ListContainer>
                       {terms.map((item, index) => (
                        <>
                            <Headers guide='true'>{item.heading}</Headers>
                            {item.guidelines.map((item, index) => (
                                <ListItem>
                                    {item.text}
                                </ListItem>
                            ))} 
                        </>
                        ))
                        }
                        <ListContainer>
                            <Headers guide='true'>Additional Terms</Headers>
                            <ListItem>Payment Terms:</ListItem>
                            <ListItem>Listing Fees:</ListItem>
                            <ListItem>Commission Rates:</ListItem>
                        </ListContainer>
                    </ListContainer>
                }
            </>
            :
            <Spinner 
                color={"rgba(17, 115, 171, 1)"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />   

        }
    </Wrapper>
  )
}

export default TermsConditions
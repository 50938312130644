import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios'



const initialState = {

    loading: false,
    user: {},
    error: '',
    status: '',
    edit: '',
    loggedIn: false
}


export const signinData  = createAsyncThunk('user/signinData', async (objdata, { rejectWithValue }) => {
        const {url, loginInfo, config} = objdata;
        try {
          const result = await axios.post(url, loginInfo, {withCredentials: true, headers: config})
          return result.data
        }catch(err){
          return rejectWithValue({status: err.response?.status, data: err.response?.data, text: err.message})
          // return {status: err.response?.status, data: err.response?.data}
        }
        // return axios
        //       .post( url, loginInfo,
        //         {
        //           withCredentials: true,
        //           headers: config
        //         }
        //       )
        //       .then(response => response.data)
      })  
const signinSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      cook: (state, action) => {
        state.user =  action.payload
        state.loggedIn = true
      },
      clearError: (state, action) => {
        state.error =  ""
      },
      roleUp: (state, action) => {
        state.user = {
            ...state.user, // Keep the existing properties
            ...action.payload, // Overwrite with the new properties
        };
      },
      updateUserData: (state, action) => {
        state.user = {
          ...state.user, // Keep the existing properties
          ...action.payload, // Overwrite with the new properties
      };
      },
      editUpdate: (state, action) => {
        state.edit =  action.payload
      },
    },
    extraReducers: builder => {
          builder.addCase(signinData.pending, state => {
            state.loading = true
            state.error=""
            state.err=""
          })
          builder.addCase(signinData.fulfilled, (state, action) => {
            state.loading = false
            state.user = action.payload
            state.error = ''
            if(state.user != null){
              state.loggedIn = true
            }else state.loggedIn = false
          })
          builder.addCase(signinData.rejected, (state, action) => {
            state.loading = false
            state.user = {}
            state.error = action.payload.data
            state.status = action.payload.status
            state.loggedIn = false
          });
         
    }
});


export default signinSlice.reducer
export const { cook, roleUp, updateUserData, clearError, editUpdate } = signinSlice.actions
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleRequest, getHandle } from '../../components/utility/drag_drop/index'
import { Headers, ListItem, Spinner, Wrapper, ListContainer, Text } from './style';

const PrivacyPolicy = () => {
    const user = useSelector((state) => state.signin.user)
    const config = {
        "Content-Type": "application/json",
        "Authorization": `JWT ${user?.tokens?.access}`
    }
    const [policies, setPolicies] = useState([]);
    const dispatch = useDispatch();
    async function RetrievePolicies(){
        const data = await getHandle({url: `${process.env.REACT_APP_BASE_APP_URL}/guide/policies/`})
        const response = handleRequest(data, dispatch, config, '')
        console.log("ff: ", response)
        if(response === 'success'){
            setPolicies(data.data);
        }
    }
    useEffect(() => {
        RetrievePolicies();
    }, [])
  return (
    <Wrapper ht={policies && policies.length > 0 ? "max-content":"100vh"}>
        {policies && policies.length > 0?
            <>
                <Headers guide='true' center="center" >Privacy Policy</Headers>
                {
                    <ListContainer>
                       {policies.map((item, index) => (
                        <>
                            <Headers guide='true'>{item.heading}</Headers>
                            {item.guidelines.map((item, index) => (
                                <ListItem>
                                    {item.text}
                                </ListItem>
                            ))} 
                        </>
                        ))
                        }
                        <ListContainer>
                            <Headers guide='true'>Additional Terms</Headers>
                            <ListItem>By using our platform, you consent to this Privacy Policy:</ListItem>
                            <ListItem>This Privacy Policy is governed by Nigerian law</ListItem>
                        </ListContainer>
                    </ListContainer>
                }
            </>
            :
            <Spinner 
                color={"rgba(17, 115, 171, 1)"}
                loading={true}
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
            />   

        }
    </Wrapper>
  )
}

export default PrivacyPolicy;